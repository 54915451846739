.c-header {
    background-color: $co-white;
    box-shadow: 0 0 1rem rgba(0, 0, 0, .2);
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $zi-header;

    &__inner {
        align-items: center;
        display: flex;
        height: $dimensions-header-height;
        justify-content: space-between;
        margin: 0 auto;
        max-width: $dimensions-content-width;
        width: 100%;
        @include media("<=wide") {
            padding-right: $ma-m;
        }
    }

    &__logo {
        height: $dimensions-header-height - 2*$ma-m;
        width: $dimensions-header-height - 2*$ma-m;

        &-link {
            background-color: $co-pri;
            height: 100%;
            padding: $ma-m;
        }
    }
}