.c-footer {
    background-color: $co-dark;

    * {
        color: $co-white;
    }

    a {
        &:hover {
            color: $co-white;
            text-decoration: underline;
        }
    }

    &__law {
        display: flex;
        justify-content: space-between;
        padding-top: $ma-xxl;

        a,
        p {
            color: $co-grey;
            font-size: $fo_s-s;
        }
    }

    @include media('<=small') {
        .c-grid__item {
            padding-bottom: $ma-m;

            &.is--c,
            &.is--r {
                text-align: left;
            }
        }
    }

    ul {
        list-style: none;
    }
}
