/*
------------------------------------------------------
--------------------- breakpoints
------------------------------------------------------
*/

$breakpoints: (
        'handheld': 21.5rem, // 340px
        'mobile': 33.5rem, //536px
        'xsmall': 40rem, // 640px
        'small': 48rem, // 768px
        'xmedium': 55.63rem, // 890px
        'medium': 64rem, // 1024px
        'wide': 80rem, // 1280px
        'large': 90rem, // 1440px
        'screen': 95rem, // 1520px
        'xlarge': 100rem // 1600px
);


/*
------------------------------------------------------
--------------------- colors
------------------------------------------------------
*/

/* general */
$co-pri: #28784D;
$co-pri-rgb: rgba(40, 120, 77, .7);
$co-sec: #28546C;
$co-body: #F7F7F7;
$co-white: #FFFFFF;
$co-dark: #212121;
$co-grey: #858585;

/* font */
$co_fo-pri : $co-dark;
$co_fo-war : #AA5239;
$co_fo-inf : $co-pri;

/* special */
$co_sp-light : #EEE;


/*
------------------------------------------------------
--------------------- dimensions
------------------------------------------------------
*/

/* content */
$dimensions-content-width: 80rem;

/* header */
$dimensions-header-height: 5rem;


/*
------------------------------------------------------
--------------------- font
------------------------------------------------------
*/

/* --- font size */

/* general */
$fo_s-s    : .75rem;
$fo_s-m    : 1rem;
$fo_s-l    : 1.5rem;
$fo_s-xl   : 2rem;
$fo_s-menu : 2.5rem;
$fo_s-xxl  : 3rem;

/* headlines */
$fo_s-h1: $fo_s-xxl;
$fo_s-h2: $fo_s-xl;
$fo_s-h3: $fo_s-l;
$fo_s-h4: 1.375rem;
$fo_s-h5: 1.25rem;
$fo_s-h6: 1.125rem;

/* --- font weight */
$fo_w-l: 200;
$fo_w-n: 400;
$fo_w-b: 700;

/* --- font line-height */
$fo_lh-m: 1.6;


/*
------------------------------------------------------
--------------------- margin
------------------------------------------------------
*/

/* general */
$ma-s    : .5rem;
$ma-m    : 1rem;
$ma-l    : 1.5rem;
$ma-xl   : 2rem;
$ma-xxl  : 3rem;
$ma-xxxl : 5rem;

/* general */
$ma-co   : 5rem;


/*
------------------------------------------------------
--------------------- transition
------------------------------------------------------
*/

/* general */
$transition: .2s ease-in;


/*
------------------------------------------------------
--------------------- z-indexes
------------------------------------------------------
*/

/* general */
$zi-main   : 100;
$zi-menu   : 200;
$zi-header : 300;
