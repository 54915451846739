.c-menu {
    @include media("<=small") {
        background-color: $co-pri;
        height: calc(100% - #{$dimensions-header-height});
        left: 0;
        padding: $ma-m;
        position: fixed;
        top: $dimensions-header-height;
        transform: translateX(-100%);
        width: 100%;
        z-index: $zi-menu;

        &.is--visible {
            transform: rotateX(0);
        }
    }

    ul {
        @include media("<=small") {
            padding-top: $ma-xxl;
        }
    }

    li {
        display: inline-block;
        @include media("<=small") {
            display: block;
            text-align: center;
        }
    }

    a {
        padding: $ma-m 0;
        @include media(">small") {
            padding: 0 $ma-s;
        }

        span {
            @include media("<=small") {
                color: $co-white;
                font-size: $fo_s-menu;
                font-weight: bold;
            }
        }

        &.is--active, &:hover {
            span {
                color: $co-pri;
                @include media("<=small") {
                    color: $co-white;
                    text-decoration: underline;
                }
            }
        }

        &.is--active {
            font-weight: bold;
        }
    }
}