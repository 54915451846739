.c-person {
    list-style: none;

    &:before {
        border: solid $ma-xl transparent;
        border-top-color: $co_sp-light;
        content: " ";
        display: block;
        left: 50%;
        position: absolute;
        top: 0;
        transform: translateX(-$ma-xl);
    }

    &__desc {
        h2 {
            padding-bottom: 0;
        }
    }

    li {
        display: none;

        &.is--active {
            display: block;
        }
    }

    &__items div:hover {
        cursor: pointer;
    }

    &__img {
        border-radius: 50%;
        height: auto;
        transition: transform $transition;
        width: 10rem;

        @include media("<=xsmall") {
            width: 5rem;
        }
    }

    &__name {
        font-size: $fo_s-l;
        padding-top: $ma-m;
    }

    &__text {
        padding-top: $ma-m;

        @include media("<=xsmall") {
            padding-top: $ma-m;
        }
    }
}