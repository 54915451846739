/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

// Settings
// ==================================================
$hamburger-padding-x                       : 0 !default;
$hamburger-padding-y                       : 15px !default;
$hamburger-layer-width                     : 40px !default;
$hamburger-layer-height                    : 2px !default;
$hamburger-layer-spacing                   : 6px !default;
$hamburger-layer-color                     : $co-pri !default;
$hamburger-layer-border-radius             : 4px !default;
$hamburger-hover-opacity                   : 1 !default;
$hamburger-hover-transition-duration       : 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;
$hamburger-hover-use-filter                : false !default;
$hamburger-hover-filter                    : opacity(50%) !default;

.e-hamburger {
    align-items: center;
    border: none;
    padding: $hamburger-padding-y $hamburger-padding-x;
    display: flex;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: $hamburger-hover-transition-duration;
    transition-timing-function: $hamburger-hover-transition-timing-function;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    overflow: visible;
    outline: none;

    @include media(">small") {
        display: none;
    }

    &:hover {
        @if $hamburger-hover-use-filter == true {
            filter: $hamburger-hover-filter;
        }
        @else {
            opacity: $hamburger-hover-opacity;
        }
    }

    &-box {
        width: $hamburger-layer-width;
        height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
        display: inline-block;
        position: relative;
    }

    &-inner {
        display: block;
        top: 50%;
        margin-top: $hamburger-layer-height / -2;

        &,
        &::before,
        &::after {
            width: $hamburger-layer-width;
            height: $hamburger-layer-height;
            background-color: $hamburger-layer-color;
            border-radius: $hamburger-layer-border-radius;
            position: absolute;
            transition-property: transform;
            transition-duration: 0.15s;
            transition-timing-function: ease;
        }

        &::before,
        &::after {
            content: "";
            display: block;
        }

        &::before {
            top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
        }

        &::after {
            bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
        }
    }

    .e-hamburger-inner {
        transition-duration: 0.22s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

        &::before {
            transition: top 0.1s 0.25s ease-in,
            opacity 0.1s ease-in;
        }

        &::after {
            transition: bottom 0.1s 0.25s ease-in,
            transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
    }

    &.is--active {
        .e-hamburger-inner {
            transform: rotate(225deg);
            transition-delay: 0.12s;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

            &::before {
                top: 0;
                opacity: 0;
                transition: top 0.1s ease-out,
                opacity 0.1s 0.12s ease-out;
            }

            &::after {
                bottom: 0;
                transform: rotate(-90deg);
                transition: bottom 0.1s ease-out,
                transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }
    }
}
