/* positions */
.po-re {
    left: 0;
    position: relative;
    top: 0;
}

.po-ab {
    left: 0;
    position: absolute;
    top: 0;
}

/* colors */
.is--grey-bg {
    background-color: $co_sp-light;
}

.is--gren-bg {
    background-color: $co-pri;
}

.is--w {
    color: $co-white;
}