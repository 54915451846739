.c-content {
    padding: $ma-co $ma-l;

    &__inner {
        margin: 0 auto;
        width: 100%;

        &.is--small {
            @include media(">medium") {
                width: 66.6666%;
            }
        }
    }
}