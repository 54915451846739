.c-hero {
    @include media(">small") {
        padding: $ma-xxxl $ma-xxl;
    }

    &__content {
        background-color: $co-pri-rgb;
        padding: $ma-xxxl $ma-xl;
        @include media(">small") {
            background-color: $co-pri;
            padding: $ma-xl;
        }
    }

    &__image {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
}