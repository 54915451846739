.c-grid {
    display: flex;
    flex-wrap: wrap;

    &__item {
        width: 50%;
        @include media(">medium") {
            width: 25%;
        }

        &:hover {
            .c-person__img {
                transform: scale(.95);
            }
        }

        .c-person__items & {
            padding-bottom: $ma-xl;
        }

        &.c_4 {
            width: 100%;
            @include media(">xsmall") {
                width: 33.3333%;
            }
        }

        &.c_9 {
            width: 100%;
            @include media(">xsmall") {
                width: 75%;
            }
        }

        &.is--6 {
            width: 100%;

            @include media(">xsmall") {
                width: 50%;
            }
        }

        &.is--m_12 {
            width: 100%;
            @include media(">xsmall") {
                width: 25%;
            }
        }

        &.is--c {
            text-align: center;
        }

        &.is--r {
            text-align: right;
        }

        @include media(">xsmall") {
            &.is--r {
                text-align: center;
            }
        }
    }
}