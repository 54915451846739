/* settings */
/*
------------------------------------------------------
--------------------- breakpoints
------------------------------------------------------
*/
/*
------------------------------------------------------
--------------------- colors
------------------------------------------------------
*/
/* general */
/* font */
/* special */
/*
------------------------------------------------------
--------------------- dimensions
------------------------------------------------------
*/
/* content */
/* header */
/*
------------------------------------------------------
--------------------- font
------------------------------------------------------
*/
/* --- font size */
/* general */
/* headlines */
/* --- font weight */
/* --- font line-height */
/*
------------------------------------------------------
--------------------- margin
------------------------------------------------------
*/
/* general */
/* general */
/*
------------------------------------------------------
--------------------- transition
------------------------------------------------------
*/
/* general */
/*
------------------------------------------------------
--------------------- z-indexes
------------------------------------------------------
*/
/* general */
/* resets */
* {
  border: 0;
  box-sizing: border-box;
  color: #212121;
  font-family: 'Raleway', sans-serif;
  margin: 0;
  outline: 0;
  padding: 0;
}

html, body, div, span,
h1, h2, h3, h4, h5, h6, p, blockquote,
address,
img,
b,
dl, dt, dd, ol, ul, li, form, label,
table, tr, th, td,
article, figure,
footer, header, menu, nav, section {
  background: transparent;
  font-size: 100%;
  vertical-align: baseline;
}

body {
  animation: bodyAnimation 1s;
  line-height: 1.6;
  opacity: 1;
}

figure, footer, header, menu, nav, section {
  display: block;
}

address {
  font-style: normal;
}

ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

a {
  background: transparent;
  font-size: 100%;
  text-decoration: none;
  vertical-align: baseline;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input, select {
  vertical-align: middle;
}

/* mixins */
/*
------------------------------------------------------
--------------------- Mixin & Functions :: Effects
------------------------------------------------------
*/
/*
------------------------------------------------------
--------------------- Mixin & Functions :: Snippets
------------------------------------------------------
*/
/*
------------------------------------------------------
--------------------- Mixin & Functions :: MediaQueries
------------------------------------------------------
*/
/* resourcen */
/* fonts */
ul {
  list-style: inherit;
}

ul li {
  padding: 0.5rem 0;
}

h1 {
  color: #FFFFFF;
  font-size: 2rem;
}

h2 {
  font-size: 2rem;
  padding-bottom: 2rem;
}

.is--little-h2 h2 {
  font-size: 1.375rem;
  padding: 3rem 0 1rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.375rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1.125rem;
}

@media (min-width: 40.1rem) {
  h1 {
    font-size: 3rem;
  }
}

/* general */
/* positions */
.po-re {
  left: 0;
  position: relative;
  top: 0;
}

.po-ab {
  left: 0;
  position: absolute;
  top: 0;
}

/* colors */
.is--grey-bg {
  background-color: #EEE;
}

.is--gren-bg {
  background-color: #28784D;
}

.is--w {
  color: #FFFFFF;
}

/* components */
.c-content {
  padding: 5rem 1.5rem;
}

.c-content__inner {
  margin: 0 auto;
  width: 100%;
}

@media (min-width: 64.1rem) {
  .c-content__inner.is--small {
    width: 66.6666%;
  }
}

.c-footer {
  background-color: #212121;
}

.c-footer * {
  color: #FFFFFF;
}

.c-footer a:hover {
  color: #FFFFFF;
  text-decoration: underline;
}

.c-footer__law {
  display: flex;
  justify-content: space-between;
  padding-top: 3rem;
}

.c-footer__law a,
.c-footer__law p {
  color: #858585;
  font-size: 0.75rem;
}

@media (max-width: 48rem) {
  .c-footer .c-grid__item {
    padding-bottom: 1rem;
  }
  .c-footer .c-grid__item.is--c, .c-footer .c-grid__item.is--r {
    text-align: left;
  }
}

.c-footer ul {
  list-style: none;
}

.c-grid {
  display: flex;
  flex-wrap: wrap;
}

.c-grid__item {
  width: 50%;
}

@media (min-width: 64.1rem) {
  .c-grid__item {
    width: 25%;
  }
}

.c-grid__item:hover .c-person__img {
  transform: scale(0.95);
}

.c-person__items .c-grid__item {
  padding-bottom: 2rem;
}

.c-grid__item.c_4 {
  width: 100%;
}

@media (min-width: 40.1rem) {
  .c-grid__item.c_4 {
    width: 33.3333%;
  }
}

.c-grid__item.c_9 {
  width: 100%;
}

@media (min-width: 40.1rem) {
  .c-grid__item.c_9 {
    width: 75%;
  }
}

.c-grid__item.is--6 {
  width: 100%;
}

@media (min-width: 40.1rem) {
  .c-grid__item.is--6 {
    width: 50%;
  }
}

.c-grid__item.is--m_12 {
  width: 100%;
}

@media (min-width: 40.1rem) {
  .c-grid__item.is--m_12 {
    width: 25%;
  }
}

.c-grid__item.is--c {
  text-align: center;
}

.c-grid__item.is--r {
  text-align: right;
}

@media (min-width: 40.1rem) {
  .c-grid__item.is--r {
    text-align: center;
  }
}

.c-header {
  background-color: #FFFFFF;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 300;
}

.c-header__inner {
  align-items: center;
  display: flex;
  height: 5rem;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 80rem;
  width: 100%;
}

@media (max-width: 80rem) {
  .c-header__inner {
    padding-right: 1rem;
  }
}

.c-header__logo {
  height: 3rem;
  width: 3rem;
}

.c-header__logo-link {
  background-color: #28784D;
  height: 100%;
  padding: 1rem;
}

@media (min-width: 48.1rem) {
  .c-hero {
    padding: 5rem 3rem;
  }
}

.c-hero__content {
  background-color: rgba(40, 120, 77, 0.7);
  padding: 5rem 2rem;
}

@media (min-width: 48.1rem) {
  .c-hero__content {
    background-color: #28784D;
    padding: 2rem;
  }
}

.c-hero__image {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.c-main {
  margin: 5rem auto 0 auto;
  max-width: 80rem;
  width: 100%;
}

.c-map {
  margin-top: 3rem;
  width: 100%;
}

@media (max-width: 48rem) {
  .c-menu {
    background-color: #28784D;
    height: calc(100% - 5rem);
    left: 0;
    padding: 1rem;
    position: fixed;
    top: 5rem;
    transform: translateX(-100%);
    width: 100%;
    z-index: 200;
  }
  .c-menu.is--visible {
    transform: rotateX(0);
  }
}

@media (max-width: 48rem) {
  .c-menu ul {
    padding-top: 3rem;
  }
}

.c-menu li {
  display: inline-block;
}

@media (max-width: 48rem) {
  .c-menu li {
    display: block;
    text-align: center;
  }
}

.c-menu a {
  padding: 1rem 0;
}

@media (min-width: 48.1rem) {
  .c-menu a {
    padding: 0 0.5rem;
  }
}

@media (max-width: 48rem) {
  .c-menu a span {
    color: #FFFFFF;
    font-size: 2.5rem;
    font-weight: bold;
  }
}

.c-menu a.is--active span, .c-menu a:hover span {
  color: #28784D;
}

@media (max-width: 48rem) {
  .c-menu a.is--active span, .c-menu a:hover span {
    color: #FFFFFF;
    text-decoration: underline;
  }
}

.c-menu a.is--active {
  font-weight: bold;
}

.c-person {
  list-style: none;
}

.c-person:before {
  border: solid 2rem transparent;
  border-top-color: #EEE;
  content: " ";
  display: block;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-2rem);
}

.c-person__desc h2 {
  padding-bottom: 0;
}

.c-person li {
  display: none;
}

.c-person li.is--active {
  display: block;
}

.c-person__items div:hover {
  cursor: pointer;
}

.c-person__img {
  border-radius: 50%;
  height: auto;
  transition: transform 0.2s ease-in;
  width: 10rem;
}

@media (max-width: 40rem) {
  .c-person__img {
    width: 5rem;
  }
}

.c-person__name {
  font-size: 1.5rem;
  padding-top: 1rem;
}

.c-person__text {
  padding-top: 1rem;
}

@media (max-width: 40rem) {
  .c-person__text {
    padding-top: 1rem;
  }
}

.c-text p {
  padding-bottom: 1rem;
}

.c-text p:last-of-type {
  padding-bottom: 0;
}

.c-text a {
  font-weight: bold;
}

.c-text a:hover, .c-text a:focus {
  text-decoration: underline;
}

.c-text ul li {
  margin-left: 1rem;
}

/* elements */
.e-btn {
  background-color: #28784D;
  border-radius: 1.5rem;
  color: #FFFFFF;
  display: inline-block;
  height: 3rem;
  line-height: 3rem;
  margin-top: 1rem;
  padding: 0 2rem;
}

.e-btn.is--white {
  background-color: transparent;
  border: 0.125rem solid #FFFFFF;
  color: #FFFFFF;
}

.e-btn:hover {
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.e-hamburger {
  align-items: center;
  border: none;
  padding: 15px 0;
  display: flex;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  overflow: visible;
  outline: none;
}

@media (min-width: 48.1rem) {
  .e-hamburger {
    display: none;
  }
}

.e-hamburger:hover {
  opacity: 1;
}

.e-hamburger-box {
  width: 40px;
  height: 18px;
  display: inline-block;
  position: relative;
}

.e-hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px;
}

.e-hamburger-inner, .e-hamburger-inner::before, .e-hamburger-inner::after {
  width: 40px;
  height: 2px;
  background-color: #28784D;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.e-hamburger-inner::before, .e-hamburger-inner::after {
  content: "";
  display: block;
}

.e-hamburger-inner::before {
  top: -8px;
}

.e-hamburger-inner::after {
  bottom: -8px;
}

.e-hamburger .e-hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.e-hamburger .e-hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.e-hamburger .e-hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.e-hamburger.is--active .e-hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.e-hamburger.is--active .e-hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.e-hamburger.is--active .e-hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}
