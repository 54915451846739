h1 {
    color: $co-white;
    font-size: $fo_s-xl;
}

h2 {
    font-size: $fo_s-h2;
    padding-bottom: $ma-xl;

    .is--little-h2 & {
        font-size: $fo_s-h4;
        padding: $ma-xxl 0 $ma-m;
    }
}

h3 {
    font-size: $fo_s-h3;
}

h4 {
    font-size: $fo_s-h4;
}

h5 {
    font-size: $fo_s-h5;
}

h6 {
    font-size: $fo_s-h6;
}

@include media('>xsmall') {
    h1 {
        font-size: $fo_s-h1;
    }
}
