.c-text {
    p {
        padding-bottom: $ma-m;

        &:last-of-type {
            padding-bottom: 0;
        }
    }

    a {
        font-weight: bold;

        &:hover, &:focus {
            text-decoration: underline;
        }
    }

    ul {
        li {
            margin-left: $ma-m;
        }
    }
}