.e-btn {
    background-color: $co-pri;
    border-radius: $fo_s-xxl / 2;
    color: $co-white;
    display: inline-block;
    height: $fo_s-xxl;
    line-height: $fo_s-xxl;
    margin-top: $ma-m;
    padding: 0 $ma-xl;

    &.is--white {
        background-color: transparent;
        border: .125rem solid $co-white;
        color: $co-white;
    }

    &:hover {
        box-shadow: 0 0 2rem rgba(0, 0, 0, .2);
    }
}
