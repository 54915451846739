* {
    border: 0;
    box-sizing: border-box;
    color: $co-dark;
    font-family: 'Raleway', sans-serif;
    margin: 0;
    outline: 0;
    padding: 0;
}
html, body, div, span,
h1, h2, h3, h4, h5, h6, p, blockquote,
address,
img,
b,
dl, dt, dd, ol, ul, li, form, label,
table, tr, th, td,
article, figure,
footer, header, menu, nav, section {
    background: transparent;
    font-size: 100%;
    vertical-align: baseline;
}
body {
    animation: bodyAnimation 1s;
    line-height: $fo_lh-m;
    opacity: 1;
}
figure,footer,header,menu,nav,section {
    display: block;
}
address {
    font-style: normal;
}
ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
a {
    background: transparent;
    font-size: 100%;
    text-decoration: none;
    vertical-align: baseline;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
input, select {
    vertical-align: middle;
}